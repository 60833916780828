import React from "react"

import { Button } from "components/anti/buttons/buttons"
import { CoverContent } from "components/anti/cover/cover"
import { useScrollAnim } from "src/components/hooks/hooks"

export const AboutRequest = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  const block = data

  return (
    <CoverContent
      theme="dark"
      img={block.image && block.image.sourceUrl}
      className="about-request"
      contentMaxWidth="mw-md-900px"
      imgHeight="h-400px w-100"
      forwardRef={trigger}
    >
      {block.title && <h2 className={`h2 ${anim(1)}`}>{block?.title}</h2>}
      {block.text && (
        <h2 className={`h2 font-weight-normal ${anim(2)}`}>{block?.text}</h2>
      )}
      {block.button && (
        <div className={`btn-group d-block ${anim(3)}`}>
          <Button
            variant="link"
            link={block?.button?.url}
            className=" w-ic-angle-right"
          >
            {block?.button?.text}
          </Button>
        </div>
      )}
    </CoverContent>
  )
}
